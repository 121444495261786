<template>
  <div class="p-10">
    <title-nav :title="'환불현황'" :nav="'결제관리 /환불 현황'" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <b-row>
            <b-col>
              <search @search="item => setCondition(item)"></search>
            </b-col>
            <b-col>
              <b-form-select
                v-model="ordWayCd"
                :options="paymentOptions"
                size="sm"
                @change="changePaymentType"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled>-- 결제수단 --</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>

            <b-col>
              <b-input-group size="sm">
                <b-input-group-append>
                  <datepicker
                    id="from-datepicker-util"
                    v-model="start_date"
                    v-on:selected="changeStartDate"
                    name="start_date"
                    :value="start_date.date"
                    :format="start_format"
                    placeholder="시작일"
                    input-class="form-control custom-select-sm"
                  />
                  ~
                  <datepicker
                    id="to-datepicker-util"
                    v-model="end_date"
                    v-on:selected="changeEndDate"
                    name="end_date"
                    :value="end_date.date"
                    :format="end_format"
                    placeholder="종료일"
                    input-class="form-control custom-select-sm"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>

          <b-row class="justify-content-md-center" style="margin-top:0.5rem">
            <b-col>
              <b-button
                size="sm"
                variant="primary"
                class="mr-2"
                v-for="(item, idx) in refundCount"
                :key="idx"
              >
                {{ convertRefundStatus(item.rf_stat_cd) }}
                <b-badge variant="light" class="badgeNum">{{ item.cnt | commaFilter }}</b-badge>
              </b-button>
            </b-col>
            <b-col>
              <div class="d-flex flex-row-reverse">
                <b-button variant="danger" size="sm">
                  총 환불금액
                  <b-badge variant="light" class="badgeNum">{{
                    total_price | commaFilter
                  }}</b-badge>
                </b-button>
                <b-button variant="danger" size="sm" class="mr-2">
                  총 환불건수
                  <b-badge variant="light" class="badgeNum">{{ total_cnt | commaFilter }}</b-badge>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="card-body p-0">
          <b-table
            striped
            hover
            bordered
            class="table-sm text-sm-center"
            :borderless="false"
            :fixed="true"
            :no-border-collapse="false"
            :head-variant="headVariant"
            :items="result_list"
            :fields="fields"
            :busy="isBusy"
            show-empty
            stickyHeader
            responsivee
          >
            <template v-slot:cell(rf_sn)="data">{{ data.value }}</template>
            <template v-slot:cell(ord_sn)="data">{{ data.value }}</template>
            <template v-slot:cell(reg_dt)="data">{{ data.value | dateFilter }}</template>
            <template v-slot:cell(ord_dt)="data">{{ data.value | dateFilter }}</template>
            <template v-slot:cell(user_sn)="data">
              <router-link
                :to="{ name: 'detail', params: { usersn: data.value } }"
                variant="link"
                >{{ data.value }}</router-link
              >
            </template>
            <template v-slot:cell(user_name)="data">{{ data.value }}</template>
            <template v-slot:cell(email)="data">{{ data.value }}</template>
            <template v-slot:cell(price)="data">{{ data.value | commaFilter }}</template>
            <template v-slot:cell(ord_way_cd)="data">{{ convertPaymentType(data.value) }}</template>
            <template v-slot:cell(title)="data">{{ data.value | commaFilter }}</template>
            <template v-slot:cell(rf_stat_cd)="data">{{
              convertRefundStatus(data.value)
            }}</template>
            <template v-slot:cell(upd_dt)="data">
              {{ data.value ? data.value : '-' }}
            </template>
            <template v-slot:cell(admin_sn)="data">
              {{ data.value ? data.value : '미처리' }}
            </template>
            <template v-slot:cell(admin_name)="data">
              <!-- 환불완료/환불취소인 경우 더이상 작업할 수 없음 -->
              <template v-if="(data.item.rf_stat_cd == '03') | (data.item.rf_stat_cd == '04')">
                {{ data.value }}
              </template>
              <template v-else>
                <b-button variant="link" @click="openModal('modal-refund-status', data.item)">
                  {{ data.value ? data.value : '미처리' }}
                </b-button>
              </template>
            </template>
            <template v-slot:head(admin_name)>
              <span v-b-tooltip.hover.top="'환불완료 , 환불취소 인 경우 더이상 수정 불가합니다.'">
                운영자확인
                <b-icon icon="question-circle" />
              </span>
            </template>
            <template v-slot:cell(refund_price)="data">
              {{ data.value | commaFilter }}
            </template>
            <template v-slot:cell(confirm_dt)="data">{{ data.value | dateFilter }}</template>
            <template v-slot:cell(refund_dt)="data">{{ data.value | dateFilter }}</template>
            <template v-slot:head(refund_dt)
              ><span v-b-tooltip.hover.top="'환불완료한 날짜만 표현'">
                환불완료
                <b-icon icon="question-circle" /> </span
            ></template>
          </b-table>
        </div>
        <div class="card-footer clearfix">
          <b-pagination
            v-model="curPage"
            :total-rows="records"
            :per-page="rowPerPage"
            @change="setPage"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </b-container>
    <!-- 환불 상태 변경 모달 -->
    <refund-modal-view
      ref="refModal"
      :modal_refund="modal_refund"
      :refundStatusOptions="refundStatusOptions"
    />
  </div>
</template>

<script>
import Search from '../util/Search'
import { getRefundList, getRefundDetail, updateRefund } from '@/api/payment'

import Datepicker from 'vuejs-datepicker/dist/vuejs-datepicker.esm.js'
import { getPaymentType, getPayRefundState, getPayRefundType } from '../../api/code'
import RefundModalView from '../util/RefundModalView.vue'
var now = new Date()
var monthFirstDate = new Date(now.getUTCFullYear(), now.getMonth(), 1)
var monthLastDate = new Date(now.getUTCFullYear(), now.getMonth() + 1, 0)

export default {
  name: 'Pay_refund',
  components: {
    Search,
    Datepicker,
    RefundModalView
  },
  data() {
    return {
      start_date: this.$moment(monthFirstDate).format('YYYY/MM/DD'),
      end_date: this.$moment(monthLastDate).format('YYYY/MM/DD'),
      start_format: 'yyyy/MM/dd',
      end_format: 'yyyy/MM/dd',
      striped: true,
      bordered: true,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      isBusy: false,
      noCollapse: false,
      records: 0,
      headVariant: 'dark',
      total_price: 0,
      total_cnt: 0,
      result_list: [],
      startDate: '',
      endDate: '',
      search: '',
      curPage: 1,
      rowPerPage: 20,
      usersn: '',
      email: '',
      user_name: '',
      REFUND_CODE: [],
      ORD_SN_CD: [],
      ORD_WAY_CD: [],
      PAYMENT_CODE: '',
      REFUND_STATUS_CODE: [],
      selectRefundStatus: '',
      refundStatusOptions: [],
      paymentOptions: [],
      ordWayCd: null,
      refundPrice: 0,
      refundCount: [],
      modal_refund: {
        name: '', //회원
        email: '', //회원email
        product: null, //환불상품
        payType: null, //결제수단
        price: 0, //결제금액
        paid: 0, //사용금액
        reasonType: '',
        reason: ''
      },
      fields: [
        {
          key: 'rf_sn',
          label: '환불No.',
          colType: 'span'
        },
        {
          key: 'ord_sn',
          label: '주문No.',
          colType: 'span'
        },
        {
          key: 'reg_dt',
          label: '환불요청일',
          colType: 'span',
          thClass: 'w10'
        },
        {
          key: 'ord_dt',
          label: '주문일',
          colType: 'span',
          thClass: 'w10'
        },
        {
          key: 'user_sn',
          label: '회원번호',
          colType: 'span'
        },
        {
          key: 'user_name',
          label: '회원이름',
          colType: 'span'
        },
        {
          key: 'email',
          label: '회원Email',
          colType: 'span'
        },
        {
          key: 'title',
          label: '환불상품',
          colType: 'span'
        },
        {
          key: 'ord_way_cd',
          label: '결제수단',
          colType: 'span'
        },
        {
          key: 'price',
          label: '결제금액',
          colType: 'span'
        },
        {
          key: 'refund_price',
          label: '환불금액',
          colType: 'span'
        },
        {
          key: 'admin_name',
          label: '운영자확인',
          colType: 'span'
        },
        {
          key: 'rf_stat_cd',
          label: '환불상태',
          colType: 'span'
        },
        {
          key: 'confirm_dt',
          label: '환불확인',
          colType: 'span'
        },
        {
          key: 'refund_dt',
          label: '환불완료',
          colType: 'span'
        }
      ]
    }
  },
  methods: {
    convertPaymentType(code) {
      if (code && this.PAYMENT_CODE) {
        // console.log(code)
        const name = this.PAYMENT_CODE.find(value => value.cd_value === code)?.cd_name_kr
        if (!name) {
          return 'cd_value not matching..'
        }
        return name
      } else return 'paymentType error'
    } /** 환불 수단 */,
    convertRefundType(code) {
      if (code) {
        const name = this.REFUND_CODE.find(value => value.cd_value == code)?.cd_name_kr
        if (!name) {
          return 'cd_value not matching..'
        }
        return name
      }
      return 'RefudnType Error'
    },
    /**
     * 환불상태
     */
    convertRefundStatus(code) {
      if (code) {
        const name = this.REFUND_STATUS_CODE.find(value => value.cd_value == code)?.cd_name_kr
        if (!name) {
          return 'cd_value not matching..'
        }
        return name
      }
      return 'code is Nan'
    },
    async initPaymentType() {
      var resp = await getPaymentType()
      this.PAYMENT_CODE = resp
      resp.map((item, idx) => {
        if (idx > 0) this.paymentOptions.push({ value: item.cd_value, text: item.cd_name_kr })
      })
    },
    async initRefundType() {
      var resp = await getPayRefundType()
      this.REFUND_CODE = resp
    },
    async initRefundStatusType() {
      var resp = await getPayRefundState()
      this.REFUND_STATUS_CODE = resp

      resp.map(item => {
        //환불확인/환불완료/환불취소만
        //this.refundStatusOptions.push({ value: item.cd_value, text: item.cd_name_kr })
        // if (item.cd_value == '02' || item.cd_value == '03' || item.cd_value == '04')
        if (item.cd_value !== '*')
          this.refundStatusOptions.push({ value: item.cd_value, text: item.cd_name_kr })
      })

      // 환불상태 변경 팝업 select data
    },
    changeStartDate(val) {
      this.start_date = this.$moment(val).format('YYYY/MM/DD')
      this.curPage = 1
      this.init(this.start_date, this.end_date)
    },
    changeEndDate(val) {
      this.end_date = this.$moment(val).format('YYYY/MM/DD')
      this.curPage = 1
      this.init(this.start_date, this.end_date)
    },
    setCondition(search) {
      this.usersn = this.email = ''
      switch (search.key) {
        case 'usersn':
          this.usersn = search.value
          break
        case 'email':
          this.email = search.value
          break
        case 'user_name':
          this.user_name = search.value
          break
        case 'referID':
          this.email = search.value
          break
      }
      this.curPage = 1
      this.search = search
      this.init(this.start_date, this.end_date)
    },
    clear() {
      this.curPage = 1
      this.rowPerPage = 20
      this.search = ''
      this.start_date = this.$moment(monthFirstDate).format('YYYY/MM/DD')
      this.end_date = this.$moment(monthLastDate).format('YYYY/MM/DD')
      this.ordWayCd = null
      this.init(this.start_date, this.end_date)
    },
    setPage(page) {
      this.curPage = page
      this.result_list = []
      this.init(this.start_date, this.end_date)
    },
    changePaymentType() {
      this.init(this.start_date, this.end_date)
    },
    async init(startDate = this.start_date, endDate = this.end_date) {
      try {
        startDate = this.$moment(startDate).format('YYYYMMDD')
        endDate = this.$moment(endDate).format('YYYYMMDD')
        this.total_price = 0
        this.total_cnt = 0
        const resp = await getRefundList(
          this.curPage,
          this.rowPerPage,
          this.search.key,
          this.search.value,
          startDate,
          endDate,
          this.ordWayCd
        )
        if (resp.code === '0000') {
          // console.log(resp.data)
          this.result_list = resp.data.list
          this.total_cnt = resp.data.total.count
          this.total_price = resp.data.total.refund_price
          this.refundCount = resp.data.count
          this.records = this.total_cnt
        } else {
          alert(`error:${resp.code}`)
        }
      } catch (error) {
        alert(`error:${error}`)
      }
    },
    async openModal(modalName, data) {
      const resp = await getRefundDetail(data.rf_sn)
      if (resp.code == '0000') {
        const data = resp.data
        this.modal_refund = {
          user_name: data.user_name,
          title: data.title,
          ord_way_name: data.ord_way_name,
          email: data.email,
          issue_name: data.issue_name,
          user_memo: data.user_memo,
          rf_sn: data.rf_sn,
          rf_stat_cd: data.rf_stat_cd,
          admin_memo: data.admin_memo,
          price: data.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          use_price: data.use_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          refund_price: data.refund_price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
      }

      this.$refs['refModal'].$refs['modal-refund-status'].show('modal_reward')
      // this.$refs[`${modalName}`].show()
    },
    cloaseModal() {
      this.$refs['refModal'].$refs['modal-refund-status'].hide()
    },
    async updateRefundStatus(rf_sn, rf_stat_cd, admin_memo, price) {
      // console.log(rf_sn, rf_stat_cd, admin_memo, price)
      const { code, message } = await updateRefund(rf_sn, rf_stat_cd, admin_memo, price)
      if (code !== '0000') {
        alert(message)
      }
      this.cloaseModal()
      this.init()
    }
  },

  mounted() {
    this.initPaymentType()
    this.initRefundType()
    this.initRefundStatusType()
    this.init()
  }
}
</script>

<style scoped>
.myTable {
  width: 100%;
  height: 100%;
  border: 1px solid grey;
}
.myTable th {
  width: 15%;
}
.myTable td {
  width: 25%;
}
th,
td {
  border: 1px solid slategray;
}
</style>
